export const CompetitionStates = {
	INVITACION_ENVIADA: 1,
	INVITACION_RECHAZADA: 2,
	INVITACION_ACEPTADA: 3,
	EN_CURSO: 4,
	EN_REVISION: 5,
	FINALIZADO: 6,
	DECLINADO: 7
}

export const TenderStates = {
	EN_EDICION: 1,
	POR_EMPEZAR: 2,
	EN_CURSO: 3,
	EN_REVISION: 4,
	FINALIZADO: 5,
}

export const Stages = {
	INVITACION: 1,
	VISITA: 2,
	CONSULTA: 3,
	ABSOLUCION_CONSULTA: 4,
	RECEPCION_PROPUESTA: 5,
	EVALUACION_PROPUESTA: 6,
	APROBACION: 7,
	FIRMA: 8
}