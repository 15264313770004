<template>
	<b-card no-body>
		<b-card-header>
			<h5 class="mb-0 text-white">
				<b>FILTROS</b>
			</h5>
		</b-card-header>
		<b-card-body class="pt-2">
			<b-row>
				<b-col
					v-if="$can('manage','managers')"
					cols="12"
					md="4"
					class="mb-2"
				>
					<label for="businessAreas">
						<strong>ESTADO(S) DE PUBLICACIÓN</strong>
					</label>
					<v-select
						id="businessAreas"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						multiple
						v-model="formData.publicStatesArray"
						:options="options.publicStates"
						class="w-100"
						@input="inputStatesVal()"
					/>
				</b-col>

				<b-col
					cols="12"
					md="4"
					class="mb-2"
				>
					<label for="tenderStates">
						<strong>ESTADO(S)</strong>
					</label>
					<v-select
						id="tenderStates"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						multiple
						v-model="formData.tenderStatesArray"
						:options="options.tenderStates"
						class="w-100"
						@input="inputTenderStatesVal()"
					/>
				</b-col>
			</b-row>
		</b-card-body>
	</b-card>
</template>

<script>
import { ref } from "@vue/composition-api"
import vSelect from "vue-select"

export default {
	components: {
		vSelect
	},
	props: {
		options: {
			type: Object,
			required: true
		}
	},
	setup(props, { emit }) {
		// REFS
		const formData = ref({
			publicStatesArray: [],
			tenderStatesArray: []
		})

		const inputStatesVal = () => {
			if (formData.value.publicStatesArray) {
				const array = formData.value.publicStatesArray.map((item) => item.value)
				emit("update:publicStatesFilter", array.join(","))

				return array
			}

			return null
		}

		const inputTenderStatesVal = () => {
			if (formData.value.tenderStatesArray) {
				const array = formData.value.tenderStatesArray.map((item) => item.value)
				emit("update:tenderStatesFilter", array.join(","))

				return array
			}

			return null
		}

		return {
			// DATA
			formData,

			// METHODS
			inputStatesVal,
			inputTenderStatesVal
		}
	}
}
</script>

<style lang="scss" scoped>
.card-header {
	background-color: var(--primary);
	padding: 1rem 1.5rem;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>